import { Navigate, useLocation } from 'react-router-dom'
// HOOK
import useAuth from 'app/hooks/useAuth'

export default function AuthGuard({ children }) {
  const { isAuthenticated, user } = useAuth()
  const { pathname } = useLocation()

  if (isAuthenticated) {
    // Check if the current path is the login page, if so redirect to the dashboard
    if (
      (pathname === '/dashboard/accounts' ||
        pathname === '/dashboard/admins') &&
      user?.role !== 'Super Admin'
    ) {
      return <Navigate replace to="/dashboard/analytics" />
    }
    return <>{children}</>
  }

  return <Navigate replace to="/session/login" state={{ from: pathname }} />
}
