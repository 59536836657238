// DataContext.js
import useAuth from 'app/hooks/useAuth'
import { useRouter } from 'app/hooks/useRouter'
import { getRequest } from 'app/utils/request'
import PropTypes from 'prop-types'
import React, {
  useMemo,
  useEffect,
  useReducer,
  useContext,
  useCallback,
  createContext,
} from 'react'

// Initial state for the context
const initialState = {
  categories: { isLoading: false, count: 0, rows: [], page: 1, limit: 20 },
  admins: { isLoading: false, count: 0, rows: [], page: 1, limit: 20 },
  statusCodes: [],
  products: {
    isLoading: false,
    count: 0,
    rows: [],
    page: 1,
    limit: 20,
    userId: 0,
    categoryId: 0,
    name: '',
  },
  banners: { isLoading: false, count: 0, rows: [], page: 1, limit: 20 },
  attributes: {
    isLoading: false,
    count: 0,
    rows: [],
    page: 1,
    limit: 20,
    categoryId: 0,
    key: '',
  },
  coupons: { isLoading: false, count: 0, rows: [], page: 1, limit: 20 },
  faqs: { isLoading: false, count: 0, rows: [], page: 1, limit: 20 },
  config: null,
  adsConfig: [],
  notifications: { isLoading: false, count: 0, rows: [], page: 1, limit: 20 },
}

// Action types

//Notifications
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
const SET_NOTIFICATIONS_PAGE = 'SET_NOTIFICATIONS_PAGE'
const SET_NOTIFICATIONS_LIMIT = 'SET_NOTIFICATIONS_LIMIT'
const SET_NOTIFICATIONS_LOADING = 'SET_NOTIFICATIONS_LOADING'

const SET_ADMINS = 'SET_ADMINS'
const SET_ADMINS_LOADING = 'SET_ADMINS_LOADING'
const SET_ADMINS_PAGE = 'SET_ADMINS_PAGE'
const SET_ADMINS_LIMIT = 'SET_ADMINS_LIMIT'

const SET_FAQS = 'SET_FAQS'
const SET_FAQS_LOADING = 'SET_FAQS_LOADING'
const SET_CONFIG = 'SET_CONFIG'
const SET_ADS_CONFIG = 'SET_ADS_CONFIG'

const SET_CATEGORIES = 'SET_CATEGORIES'
const SET_CATEGORIES_PAGE = 'SET_CATEGORIES_PAGE'
const SET_CATEGORIES_LIMIT = 'SET_CATEGORIES_LIMIT'
const SET_CATEGORIES_LOADING = 'SET_CATEGORIES_LOADING'

const SET_BANNERS = 'SET_BANNERS'
const SET_BANNERS_PAGE = 'SET_BANNERS_PAGE'
const SET_BANNERS_LIMIT = 'SET_BANNERS_LIMIT'
const SET_BANNERS_LOADING = 'SET_BANNERS_LOADING'

const SET_COUPONS = 'SET_COUPONS'
const SET_COUPONS_PAGE = 'SET_COUPONS_PAGE'
const SET_COUPONS_LIMIT = 'SET_COUPONS_LIMIT'
const SET_COUPONS_LOADING = 'SET_COUPONS_LOADING'

const SET_ATTRIBUTES = 'SET_ATTRIBUTES'
const SET_ATTRIBUTES_PAGE = 'SET_ATTRIBUTES_PAGE'
const SET_ATTRIBUTES_CATEGORY = 'SET_ATTRIBUTES_CATEGORY'
const SET_ATTRIBUTES_KEY = 'SET_ATTRIBUTES_KEY'
const SET_ATTRIBUTES_LIMIT = 'SET_ATTRIBUTES_LIMIT'
const SET_ATTRIBUTES_LOADING = 'SET_ATTRIBUTES_LOADING'

const SET_STATUS_CODES = 'SET_STATUS_CODES'
const CLEAR_ALL_STATES = 'CLEAR_ALL_STATES'

// Reducer function to handle state changes
const dataReducer = (state, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state?.categories,
          count: action?.payload?.count,
          rows: action?.payload?.rows,
        },
      }

    case SET_CATEGORIES_PAGE: {
      return {
        ...state,
        categories: { ...state.categories, page: action.payload },
      }
    }

    case SET_CATEGORIES_LIMIT:
      return {
        ...state,
        categories: { ...state.categories, limit: action.payload },
      }

    case SET_CATEGORIES_LOADING:
      return {
        ...state,
        categories: { ...state.categories, isLoading: action.payload },
      }
    case SET_ADMINS:
      return {
        ...state,
        admins: {
          ...state?.admins,
          count: action?.payload?.count || 0,
          rows: action?.payload?.rows || [],
        },
      }

    case SET_ADMINS_PAGE: {
      return {
        ...state,
        admins: { ...state.admins, page: action.payload },
      }
    }

    case SET_ADMINS_LIMIT:
      return {
        ...state,
        admins: { ...state.admins, limit: action.payload },
      }

    case SET_ADMINS_LOADING:
      return {
        ...state,
        admins: { ...state.admins, isLoading: action.payload },
      }

    case 'SET_BANNERS':
      return {
        ...state,
        banners: {
          ...state.banners,
          count: action?.payload?.count,
          rows: action?.payload?.rows,
        },
      }

    case SET_BANNERS_PAGE: {
      return {
        ...state,
        banners: { ...state.banners, page: action.payload },
      }
    }

    case SET_BANNERS_LIMIT:
      return {
        ...state,
        banners: { ...state.banners, limit: action.payload },
      }

    case SET_BANNERS_LOADING:
      return {
        ...state,
        banners: { ...state.banners, isLoading: action.payload },
      }

    case SET_COUPONS:
      return {
        ...state,
        coupons: {
          ...state?.coupons,
          count: action?.payload?.count,
          rows: action?.payload?.rows,
        },
      }

    case SET_COUPONS_PAGE: {
      return {
        ...state,
        coupons: { ...state.coupons, page: action.payload },
      }
    }

    case SET_COUPONS_LIMIT:
      return {
        ...state,
        coupons: { ...state.coupons, limit: action.payload },
      }

    case SET_COUPONS_LOADING:
      return {
        ...state,
        coupons: { ...state.coupons, isLoading: action.payload },
      }

    case SET_ATTRIBUTES:
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          count: action?.payload?.count,
          rows: action?.payload?.rows,
        },
      }

    case SET_ATTRIBUTES_CATEGORY:
      return {
        ...state,
        attributes: { ...state.attributes, categoryId: action.payload },
      }

    case SET_ATTRIBUTES_KEY:
      return {
        ...state,
        attributes: { ...state.attributes, key: action.payload },
      }
    case SET_ATTRIBUTES_PAGE: {
      return {
        ...state,
        attributes: { ...state.attributes, page: action.payload },
      }
    }

    case SET_ATTRIBUTES_LIMIT:
      return {
        ...state,
        attributes: { ...state.attributes, limit: action.payload },
      }

    case SET_ATTRIBUTES_LOADING:
      return {
        ...state,
        attributes: { ...state.attributes, isLoading: action.payload },
      }

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          count: action?.payload?.count || 0,
          rows: action?.payload?.rows || [],
        },
      }
    case SET_NOTIFICATIONS_LIMIT:
      return {
        ...state,
        notifications: { ...state.notifications, limit: action.payload },
      }
    case SET_NOTIFICATIONS_PAGE:
      return {
        ...state,
        notifications: { ...state.notifications, page: action.payload },
      }
    case SET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notifications: { ...state.notifications, isLoading: action.payload },
      }
    case SET_FAQS:
      return {
        ...state,
        faqs: {
          ...state?.faqs,
          count: action?.payload?.count,
          rows: action?.payload?.rows,
        },
      }

    case SET_FAQS_LOADING:
      return {
        ...state,
        faqs: { ...state.faqs, isLoading: action.payload },
      }

    case SET_CONFIG:
      return {
        ...state,
        config: action.payload,
      }

    case SET_ADS_CONFIG:
      return {
        ...state,
        adsConfig: action.payload,
      }
    case SET_STATUS_CODES:
      return {
        ...state,
        statusCodes: action?.payload,
      }
    case CLEAR_ALL_STATES:
      return initialState
    default:
      return state
  }
}

// Create the context
const DataContext = createContext()

// Context provider component
const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dataReducer, initialState)
  const { user } = useAuth()
  const router = useRouter()
  // Actions to set user, dashboard data, users, malls, shops, and categories
  //config
  const setConfig = (confg) => dispatch({ type: SET_CONFIG, payload: confg })
  //status codes
  const setStatusCodes = (codes) =>
    dispatch({
      type: SET_STATUS_CODES,
      payload: codes,
    })
  //adsConfig
  const setAdsConfig = (adsConfg) =>
    dispatch({ type: SET_ADS_CONFIG, payload: adsConfg })

  //banners
  const setBanners = (banners) =>
    dispatch({ type: SET_BANNERS, payload: banners })
  const setBannersPage = (page) =>
    dispatch({ type: SET_BANNERS_PAGE, payload: page })
  const setBannersLimit = (limit) =>
    dispatch({ type: SET_BANNERS_LIMIT, payload: limit })
  const setBannersLoading = (loading) =>
    dispatch({ type: SET_BANNERS_LOADING, payload: loading })

  //categories
  const setCategories = (categories) =>
    dispatch({ type: SET_CATEGORIES, payload: categories })
  const setCategoriesPage = (page) =>
    dispatch({ type: SET_CATEGORIES_PAGE, payload: page })
  const setCategoriesLimit = (limit) =>
    dispatch({ type: SET_CATEGORIES_LIMIT, payload: limit })
  const setCategoriesLoading = (loading) =>
    dispatch({ type: SET_CATEGORIES_LOADING, payload: loading })

  const setAdmins = (admins) => dispatch({ type: SET_ADMINS, payload: admins })
  const setAdminsLoading = (loading) =>
    dispatch({ type: SET_ADMINS_LOADING, payload: loading })

  //coupons
  const setCoupons = (coupons) =>
    dispatch({ type: SET_COUPONS, payload: coupons })
  const setCouponsPage = (page) =>
    dispatch({ type: SET_COUPONS_PAGE, payload: page })
  const setCouponsLimit = (limit) =>
    dispatch({ type: SET_COUPONS_LIMIT, payload: limit })
  const setCouponsLoading = (loading) =>
    dispatch({ type: SET_COUPONS_LOADING, payload: loading })

  //notifications
  const setNotifications = (nots) =>
    dispatch({ type: SET_NOTIFICATIONS, payload: nots })
  const setNotificationsPage = (page) =>
    dispatch({ type: SET_NOTIFICATIONS_PAGE, payload: page })
  const setNotificationsLimit = (limit) =>
    dispatch({ type: SET_NOTIFICATIONS_LIMIT, payload: limit })
  const setNotificationsLoading = (loading) =>
    dispatch({ type: SET_NOTIFICATIONS_LOADING, payload: loading })

  //attributes
  const setAttributes = (attributes) =>
    dispatch({ type: SET_ATTRIBUTES, payload: attributes })
  const setAttributesCategory = (cat) =>
    dispatch({ type: SET_ATTRIBUTES_CATEGORY, payload: cat })
  const setAttributesKey = (key) =>
    dispatch({ type: SET_ATTRIBUTES_KEY, payload: key })
  const setAttributesPage = (page) =>
    dispatch({ type: SET_ATTRIBUTES_PAGE, payload: page })
  const setAttributesLimit = (limit) =>
    dispatch({ type: SET_ATTRIBUTES_LIMIT, payload: limit })
  const setAttributesLoading = (loading) =>
    dispatch({ type: SET_ATTRIBUTES_LOADING, payload: loading })

  //faqs
  const setFaqs = (faqs) => dispatch({ type: SET_FAQS, payload: faqs })
  const setFaqsLoading = (loading) =>
    dispatch({ type: SET_FAQS_LOADING, payload: loading })

  const clearAllStatesAction = () => dispatch({ type: CLEAR_ALL_STATES })

  const fetchAdmins = useCallback(async () => {
    const offset = (state.admins.page - 1) * state.admins.limit
    setAdminsLoading(true)
    const res = await getRequest(
      `/auth/get-users?limit=${state.admins.limit}&offset=${offset}&role=admin`,
      router,
    )
    setAdminsLoading(false)
    setAdmins(res?.data)
  }, [state.admins.page, state.admins.limit])

  const fetchCategories = useCallback(async () => {
    const offset = (state.categories.page - 1) * state.categories.limit
    setCategoriesLoading(true)
    const res = await getRequest(
      `/categories/?limit=${state.categories.limit}&offset=${offset}`,
      router,
    )
    setCategoriesLoading(false)
    setCategories(res?.data)
  }, [state.categories.page, state.categories.limit])

  const fetchCoupons = useCallback(async () => {
    const offset = (state.coupons.page - 1) * state.coupons.limit
    setCouponsLoading(true)
    const res = await getRequest(
      `/cobones/all?limit=${state.coupons.limit}&offset=${offset}`,
      router,
    )
    setCouponsLoading(false)
    setCoupons(res?.data)
  }, [state.coupons.page, state.coupons.limit])

  const fetchAttributes = useCallback(async () => {
    const offset = (state.attributes.page - 1) * state.attributes.limit
    let url = `/custom-attributes/?limit=${state.attributes.limit}&offset=${offset}`
    if (state.attributes.categoryId !== 0) {
      url += `&categoryId=${state.attributes.categoryId}`
    }
    if (state.attributes.key !== '') {
      url += `&key=${state.attributes.key}`
    }
    setAttributesLoading(true)
    const res = await getRequest(url, router)
    setAttributesLoading(false)
    setAttributes(res?.data)
  }, [
    state.attributes.page,
    state.attributes.categoryId,
    state.attributes.key,
    state.attributes.limit,
  ])

  const fetchFaqs = useCallback(async () => {
    const offset = (state.faqs.page - 1) * state.faqs.limit
    setFaqsLoading(true)
    const res = await getRequest(
      `/FAQs/all?limit=${state.faqs.limit}&offset=${offset}`,
      router,
    )
    setFaqsLoading(false)
    setFaqs(res?.data)
  }, [state.faqs.page, state.faqs.limit])

  const fetchNotifications = useCallback(async () => {
    const offset = (state.notifications.page - 1) * state.notifications.limit
    setNotificationsLoading(true)
    const res = await getRequest(
      `/notifications/user?offset=${offset}&limit=${state.notifications.limit}`,
      router,
    )
    setNotificationsLoading(false)
    setNotifications(res?.data)
  }, [state.notifications.page, state.notifications.limit])

  const fetchBanners = useCallback(async () => {
    setBannersLoading(true)
    const res = await getRequest('/banners/')
    setBanners(res?.data)
    setBannersLoading(false)
  }, [])

  const fetchConfig = useCallback(async () => {
    const res = await getRequest('/configurations/', router)
    // console.log(res?.data)
    setConfig(res?.data)
  }, [])

  const fetchAdsConfig = useCallback(async () => {
    const res = await getRequest('/ads/all-ads-configurations', router)
    // console.log(res?.data)
    setAdsConfig(res?.data?.rows)
  }, [])

  const fetchStatusCodes = useCallback(async () => {
    const res = await getRequest('/order/order-status', router)
    setStatusCodes(res?.data)
  }, [])

  const clearAllStates = useCallback(() => {
    clearAllStatesAction()
  }, [])

  useEffect(() => {
    if (user !== null) {
      fetchAdmins()
    }
  }, [user, fetchAdmins])

  useEffect(() => {
    if (user !== null) {
      fetchAttributes()
    }
  }, [user, fetchAttributes])

  useEffect(() => {
    if (user) fetchNotifications()
  }, [user, fetchNotifications])

  useEffect(() => {
    if (user !== null) {
      fetchCategories()
      fetchCoupons()
      fetchAdsConfig()
      fetchBanners()
      fetchStatusCodes()
      fetchFaqs()
      fetchConfig()
    }
  }, [
    user,
    fetchCategories,
    fetchBanners,
    fetchCoupons,
    fetchStatusCodes,
    fetchFaqs,
    fetchConfig,
    fetchAdsConfig,
  ])

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      ...state,
      setCategories,
      setCategoriesPage,
      setCategoriesLimit,
      setAttributes,
      setAttributesKey,
      setAttributesCategory,
      setAttributesPage,
      setAttributesLimit,
      setNotificationsPage,
      setNotificationsLimit,
      setFaqs,
      setCoupons,
      setCouponsPage,
      setCouponsLimit,
      setBanners,
      setConfig,
      setAdmins,
      setAdsConfig,
      clearAllStates,
      fetchCategories,
      fetchAttributes,
      fetchBanners,
      fetchAdsConfig,
      fetchCoupons,
      fetchConfig,
      fetchAdmins,
      fetchFaqs,
      fetchNotifications,
    }),
    [state], // Only recompute the value when state changes
  )

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  )
}

// Custom hook to use the context
const useDataContext = () => {
  const context = useContext(DataContext)
  if (!context) {
    throw new Error('useDataContext must be used within a DataProvider')
  }
  return context
}

DataProvider.propTypes = {
  children: PropTypes.node,
}

export { DataProvider, useDataContext }
